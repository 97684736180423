<template>
  <page-container class="sign-in-container">
    <div class="sign-in-set-container">
      <div :style="{ visibility: signInDataList.length? 'visible' : 'hidden' }" class="sign-in-set-info">
        <span class="sign-in-total-info">{{ chainTotal }}</span>
<!--        <span class="sign-in-points-info">{{ signInDataList.length * 10 }}</span>-->
      </div>

      <van-button
          v-if="isSignIn === false"
          class="sign-in-btn"
          round type="info"
          color="linear-gradient(to bottom, #fad671, #fbb11c)"
          @click="toSignIn"
      >
        签到
      </van-button>
      <div v-if="isSignIn === true" class="is-sign-in-text">今日已签到</div>
<!--      <p class="sign-in-tip-info">今日签到可领取10分</p>-->
      <p class="sign-in-tip-info">&nbsp;</p>
    </div>

    <van-calendar
        class="sin-in-calendar"
        :poppable="false"
        :show-confirm="false"
        :show-subtitle="false"
        :show-mark="false"
        :readonly="true"
        first-day-of-week="1"
        :min-date="minDate"
        :max-date="maxDate"
    >
      <template #title>
        <div class="title-container">
          <!--                    <div>-->
          <!--                      <van-button-->
          <!--                          @click="setSignInData(-1)"-->
          <!--                          name="arrow-left" type="primary" plain round size="mini"-->
          <!--                      >-->
          <!--                        上个月-->
          <!--                      </van-button>-->
          <!--                    </div>-->
          <div>{{ displayYM }}</div>
          <!--                    <div>-->
          <!--                      <van-button-->
          <!--                          v-if="start"-->
          <!--                          @click="setSignInData(1)"-->
          <!--                          name="arrow" type="primary" plain round icon-position="right" size="mini"-->
          <!--                      >-->
          <!--                        下个月-->
          <!--                      </van-button>-->
          <!--                    </div>-->
        </div>
      </template>
      <template #bottom-info="data">
        <div
            class="day-text"
            :class="{ 'is-sign-in-day': isSignInMap[data.text] }"
        >{{ data.text }}
        </div>
        <!-- 已签 -->
        <van-icon
            v-if="isSignInMap[data.text]"
            name="success"
        />
      </template>
    </van-calendar>
  </page-container>
</template>

<script>
import moment from 'moment';
import { getUserIsSign, getUserSignList, userSignIn } from '@/api/user';
import { Toast } from 'vant';

const testApi = params => new Promise(resolve => setTimeout(() => {
  let { startTime, endTime } = params;
  let dayLength = moment(endTime).diff(startTime, 'day');
  let dataList = [];
  while (dayLength > -1) {
    let signDate = moment(endTime).add(-dayLength, 'day').format('YYYY-MM-DD');
    Math.random() > .2 && dataList.push({
      signDate,
    });
    dayLength--;
  }
  console.log(JSON.parse(JSON.stringify(dataList)));

  resolve(dataList);
}, 321));

export default {
  name: 'signIn',
  data() {
    return {
      start: 0,
      signInDataList: [],
      isSignIn: null,
    };
  },
  computed: {
    timeRange() {
      let { start } = this;
      let thisMoment = start => moment().add(start, 'month');
      let startMoment = thisMoment(start).startOf('month');
      let endMoment = start < 0 ? thisMoment(start).endOf('month') : moment();
      return [startMoment.toDate(), endMoment.toDate()];
    },
    minDate() {
      return this.timeRange[0];
    },
    maxDate() {
      return this.timeRange[1];
    },
    displayYM() {
      return `签到日历（${moment(this.maxDate).format('YYYY年M月')}）`;
    },
    isSignInMap() {
      let { signInDataList } = this;
      return [{}, ...signInDataList].reduce((map, data) => {
        let { signDate } = data;
        map[moment(signDate).format('D')] = true;
        return map;
      });
    },
    chainTotal() {
      let { signInDataList } = this;
      let total = 0;
      if (signInDataList.length) {
        total = 1;
        signInDataList.reduce((thisDay, nextDay) => {
          let day = +moment(thisDay.signDate).format('D');
          let dayNext = +moment(nextDay.signDate).format('D');
          if (!total) total += 1;
          if (day !== dayNext) {
            if ((dayNext - 1) === day) total += 1;
            else total = 1;
          }
          return nextDay;
        });
      }
      return total;
    },
  },
  created() {
    getUserIsSign().then(res => {
      this.isSignIn = res;
    });

    this.setSignInData();
  },
  methods: {
    moment,
    toSignIn() {
      userSignIn().then(res => {
        if (res === true) {
          this.setSignInData();
          this.isSignIn = true;
          Toast('签到成功');
        }
      });
    },
    setSignInData(add = 0) {
      if (this.setSignInData.loading)
        return;
      this.setSignInData.loading = true;

      this.start += add;
      let { minDate, maxDate } = this;
      let startTime = moment(minDate).format('YYYY-MM-DD');
      let endTime = moment(maxDate).format('YYYY-MM-DD');

      getUserSignList({ startTime, endTime }).then(res => {
        this.setSignInData.loading = false;
        this.signInDataList = Array.isArray(res) ? res : [];
      }).catch(() => {
        this.setSignInData.loading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sign-in-container {
  padding: 155px 0 0 0;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: -50%;
    border-bottom-left-radius: 100%;
    border-bottom-right-radius: 100%;
    width: 200%;
    height: 200px;
    background: url("../../assets/signIn/sign-in-bg.png") center top no-repeat;
    background-size: 50% auto;
  }

  .sign-in-set-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    margin: 0 16px;
    padding: 26px 19px 16px 19px;
    height: 150px;
    background-color: #fff;
    border-radius: 10px;

    .sign-in-set-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 19px;

      .sign-in-total-info {
        font-size: 13px;
        font-weight: bolder;
        color: #f0783c;

        &:before {
          content: '已连续签到';
          margin-right: .6em;
          letter-spacing: 1px;
          color: #333;
        }

        &:after {
          content: '天';
          margin-left: .6em;
          letter-spacing: 1px;
          color: #333;
        }
      }

      .sign-in-points-info {
        display: flex;
        align-items: center;
        font-size: 19px;
        letter-spacing: 1px;
        font-weight: bolder;
        color: @primary-color;

        &:before {
          content: '积分';
          margin-right: .6em;
          font-weight: normal;
          font-size: 12px;
          color: #999;
        }
      }
    }

    .sign-in-btn {
      padding: 0 55px;
      height: 36px;
      letter-spacing: 2px;
      font-size: 15px;
      box-shadow: 0 3px 2px 1px #e39b09;

      &:active {
        box-shadow: none;
      }
    }

    .is-sign-in-text {
      font-size: 15px;
      letter-spacing: 1px;
      color: @primary-color;
    }

    .sign-in-tip-info {
      margin: 0;
      text-align: center;
      font-size: 12px;
      color: #999;
    }
  }

  .sin-in-calendar {
    margin: 0 16px;
    height: auto;
    background-color: #f5f6f8;

    /deep/ .van-calendar__header {
      box-shadow: none;
    }

    /deep/ .van-calendar__header-title {
      height: 56px;
      line-height: 56px;
    }

    /deep/ .van-calendar__day {
      height: 53px;
    }
  }

  .title-container {
    display: flex;
    //justify-content: center;
    justify-content: flex-start;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      //width: 33.3333333%;
    }

    /deep/ .van-button {
      width: 61.8%
    }
  }

  /deep/ .van-calendar__month-title {
    display: none;
  }

  /deep/ .van-calendar__day {
    color: #0000;
  }

  /deep/ .van-calendar__selected-day {
    color: #0000;
    background-color: #0000;
    border-radius: 0;
  }

  /deep/ .van-calendar__bottom-info {
    box-sizing: border-box;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #000;

    .day-text {
      margin: 4px auto 2px auto;
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      transition: all .5s;

      &.is-sign-in-day {
        color: #fff;
        background: linear-gradient(#9fe483, #42d56f);
        border-radius: 100%;
      }
    }

    .van-icon {
      font-size: 16px;
      font-weight: bolder;
      color: #e49c11;
    }
  }
}
</style>
